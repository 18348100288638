import Vue, { VNode } from 'vue';
import { DateTime as LuxonDateTime } from 'luxon';

import './Dashboard.css';
import gql from 'graphql-tag';
import Button from '@/components/Admin/Button/Button';
import Loader from '@/components/Shared/Loader/Loader';
import apollo from '@/services/apollo';
import { BookingModel } from '../Bookings/Bookings';
import { MessageModel } from '../Messages/Messages';

interface DashboardSectionProps {
  loading: boolean;
  items: DashboardItemProps[];
  url: string;
  title: string;
}

interface DashboardItemProps {
  description: string;
  id: number;
  time: string;
  title: string;
  url?: string;
  user: string;
}

const DashboardItem = (context: any) => {
  const props = context.props as DashboardItemProps;

  const {
    description,
    id,
    time,
    title,
    url,
    user,
  } = props;

  return (
    <li class="dashboard-section__item">
      <router-link to={`${url}/${id}`}>
        <span class="dashboard-section__item__avatar">{title[0]}</span>

        <div class="dashboard-section__item__details">
          <div class="dashboard-section__item__meta">
            <span>{user}</span>
            <span>{time}</span>
          </div>

          <h3 class="dashboard-section__item__title">{title}</h3>

          <span class="dashboard-section__item__description">{description}</span>

        </div>
      </router-link>
    </li>
  );
};

const DashboardSection = (context: any) => {
  const props = context.props as DashboardSectionProps;
  const {
    items,
    loading,
    title,
    url,
  } = props;

  return (
    <section class="dashboard-section">
      <div class="dashboard-section__header">
        <h2 class="dashboard-section__header__title">{title}</h2>

        <Button
          isLink
          small
          to={url}
        >View All</Button>
      </div>

      {
        loading
          ? <Loader />
          : (
            <div>
              {
                Array.isArray(items) && items.length > 0
                  ? (
                    <ul class="dashboard-section__list">
                      {
                        items.map(({
                          description, id, time, title: itemTitle, user,
                        }) => (
                            <DashboardItem
                              description={description}
                              id={id}
                              title={itemTitle}
                              time={time}
                              url={url}
                              user={user}
                            />))
                      }
                    </ul>
                  )
                  : (
                    <div class="dashboard-section__empty">
                      <em>No entries found</em>
                    </div>
                  )
              }
            </div>
          )
      }

    </section>
  );
};

interface DashboardData {
  loading: boolean;
  sections: {
    bookings: DashboardItemProps[] | null;
    messages: DashboardItemProps[] | null;
    orders: DashboardItemProps[] | null;
  };

  sectionLoading: {
    bookings: boolean;
    messages: boolean;
  };
}

const Dashboard = Vue.extend({
  data(): DashboardData {
    return {
      loading: false,
      sectionLoading: {
        bookings: true,
        messages: true,
      },
      sections: {
        bookings: null,
        messages: null,
        orders: null,
      },
    };
  },

  methods: {
    loadDashboardData() {
      // this.sections.bookings = mockDashboardItems;
      this.loadPendingBookings();

      this.loadUnreadMessages();
      // TODO: Add orders
      // this.sections.orders = mockDashboardItems;
    },

    loadPendingBookings() {
      this.sectionLoading.bookings = true;

      const query = gql`
        {
          getBookings(pendingOnly: true) {
            confirmed
            created
            id
            firstName
            lastName
            selectedDate
            selectedDay
            selectedTime
            lastName
            firstName
            selectedOffering
          }
        }
      `;

      apollo.query({
        query,
      })
        .then(({ data }) => {
          this.sections.bookings = (data?.getBookings || []).map((booking: BookingModel) => ({
            description: `${booking.selectedTime}, ${LuxonDateTime.fromISO(booking.selectedDate).toLocaleString({
              day: '2-digit',
              month: 'long',
              weekday: 'long',
            })}`,
            id: `${booking.id}?date=${booking.selectedDay}`,
            time: LuxonDateTime.fromISO(booking.created).toRelative(),
            title: `${booking.firstName} ${booking.lastName}`,
            user: booking.selectedOffering && booking.selectedOffering.split('|')[1]
              && booking.selectedOffering.split('|')[1].trim(),
          }));
        })
        .catch((err) => {
          console.log('Error loading dashboard bookings => ', err);
        })
        .finally(() => {
          this.sectionLoading.bookings = false;
        });
    },

    loadUnreadMessages() {
      this.sectionLoading.messages = true;
      const query = gql`
        {
          getMessages(unreadOnly: true){
            created
            email
            id
            name
            phone
            message
            read
            title
          }
        }
      `;

      apollo.query({
        query,
      })
        .then(({ data }) => {
          this.sections.messages = (data?.getMessages || []).map((message: MessageModel) => ({
            description: message.message,
            id: message.id,
            time: LuxonDateTime.fromISO(message.created).toRelative(),
            title: message.title || '',
            user: message.name,
          }));
        })
        .catch((err) => {
          console.log('Error loading dashboard messages => ', err);
        })
        .finally(() => {
          this.sectionLoading.messages = false;
        });
    },
  },

  mounted() {
    this.loadDashboardData();
  },

  name: 'dashboard',

  render(): VNode {
    const currentHour = new Date().getHours();
    let periodOfDay: string;
    if (currentHour < 12) periodOfDay = 'Morning';
    else if (currentHour <= 17) periodOfDay = 'Afternoon';
    else periodOfDay = 'Evening';

    return (
      <div class="dashboard">
        <header class="dashboard__header">
          <h1 class="dashboard__header__title">Good {periodOfDay}!</h1>
        </header>

        <div class="dashboard-sections">
          <DashboardSection
            items={this.sections.bookings}
            loading={this.sectionLoading.bookings}
            title='Pending Bookings'
            url="/admin/bookings"
          />

          <DashboardSection
            items={this.sections.messages}
            loading={this.sectionLoading.messages}
            title='Unread Messages'
            url="/admin/messages"
          />

          {/* <DashboardSection
            items={this.sections.orders}
            loading={this.loading}
            title='Orders'
            url="/admin/orders"
          /> */}
        </div>

      </div>
    );
  },
});

export default Dashboard;
